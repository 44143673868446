.filters {
  width: 100%;
  margin-right: 15px;

  .star-button-container {
    text-align: center;
    small {
      color: #aaa;
      margin-bottom: 8px;
      display: inline-block;
    }
  }

  .instagram {
    margin: 20px auto 0 auto;
    text-align: center;

    &-icon {
      width: 20px;
      height: 20px;
      background-image: url('../../../static/instagram-icon.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      opacity: 0.3;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    a {
      color: #aaa;
      text-decoration: none;

      &:hover {
        color: #eabf00;
      }
    }
  }

  .title {
    margin-top: 2px;
    margin-bottom: 20px;
  }

  &-available-size {
    // display: inline-block;
    margin-bottom: 10px;
    /* Customize the label (the container) */
    label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 35px;
      height: 35px;
      font-size: 1.8em;
      margin-bottom: 8px;
      margin-right: 8px;
      border-radius: 50%;
      line-height: 35px;
      text-align: center;

      /* On mouse-over, add a grey background color */
      &:hover input ~ .checkmark {
        border: 1px solid #1b1a20;
      }

      /* When the checkbox is checked, add a blue background */
      & input:checked ~ .checkmark {
        background-color: #1b1a20;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 8px;
        color: #ececec;
      }

      /* Show the checkmark when checked */
      & input:checked ~ .checkmark:after {
        display: block;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        // width: 35px;
        height: 35px;
        font-size: 0.8em;
        // border-radius: 50%;
        line-height: 35px;
        text-align: center;
        color: #1b1a20;
        background-color: #FFFFFF;
        // background-color: #ececec;

        // border: 1px solid transparent;
      }
    }
  }
}
